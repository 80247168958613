import { AuthService } from "services/auth-service";
import { autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { ChooseAccountDialog } from "./dialog/choose-account";

@autoinject()
export class Index {
    private accountType = 'Starter';

    constructor(private readonly authService: AuthService, private readonly dialogService: DialogService) {
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
        this.accountType = this.authService.pharmacy?.account;
    }

    async registerAdvanced() {
        if (!this.dialogService.hasOpenDialog) {

            const result = await this.dialogService.open({
                viewModel: ChooseAccountDialog,
                model: {
                    title: 'Are you sure you want to register for the Advanced account?',
                    accountType: 'Advanced'
                },
                lock: true,
                keyboard: true,
                startingZIndex: 99999
            }).whenClosed((value) => {
                if (!value.wasCancelled) {
                    this.authService.getCurrentPharmacy().then(() => this.accountType = this.authService.pharmacy?.account);
                }
            });
        }
    }

    async registerPremium() {
        if (!this.dialogService.hasOpenDialog) {

            const result = await this.dialogService.open({
                viewModel: ChooseAccountDialog,
                model: {
                    title: "Are you sure you want to register for the Premium account?",
                    accountType: 'Premium'
                },
                lock: true,
                keyboard: true,
                startingZIndex: 99999
            }).whenClosed((value) => {
                if (!value.wasCancelled) {
                    this.authService.getCurrentPharmacy().then(() => this.accountType = this.authService.pharmacy?.account);
                }
            });
        }
    }

}
