import { isGuid } from '@dts/scriptlib';
import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';

@autoinject()
export class Index {
    private router: Router;
    private doctor: Partial<ImpiloDb.Tables.Doctor> = new ImpiloDb.Tables.Doctor();

    constructor(private readonly repository: ImpiloRepository) {
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'search' },
            { route: 'search', name: 'search', moduleId: PLATFORM.moduleName('./search/index'), title: 'Search', nav: true },
            { route: 'doctor/:guid?', name: 'doctor', moduleId: PLATFORM.moduleName('./doctor/index'), title: 'Doctor' },
        ]);
        this.router = router;
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.doctor = await this.doctor.get(this.repository, params.guid) as ImpiloDb.Tables.Doctor;
        }
    }
}
