import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo, Store } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { doctorFullTextAction, doctorSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'doctors')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {
            if (this.filter.trim().length >= 0) {
                this.isWaiting = true;
                // const response = await (new ImpiloDb.Tables.Doctor().fulltext(this.repository, this.filter, page, size));
                const response = await (this.repository as ImpiloRepository).filterDoctor(this.filter, page, size);
                this.isWaiting = false;

                await doctorSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((doctor: ImpiloDb.Tables.Doctor) => {
                    return doctor;
                });

                return response;
            }
        }, () => doctorFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: (e: Event, args: OnEventArgs) => {
                    const doctor = args.dataContext as ImpiloDb.Tables.Doctor;
                    this.router.navigateToRoute('doctor', { guid: doctor.guid });
                }
            },
            { id: 'name', name: 'Name', field: 'name', sortable: true },
            { id: 'practice', name: 'Practice', field: 'practice', sortable: true },
            { id: 'telephoneNumber', name: 'Telephone', field: 'telephoneNumber', sortable: true },
            { id: 'cellPhoneNumber', name: 'CellPhone', field: 'cellPhoneNumber', sortable: true },
            { id: 'emailAddress', name: 'Email', field: 'emailAddress', sortable: true },
            { id: 'addressLine1', name: 'Address Line 1', field: 'addressLine1', sortable: true },
            { id: 'addressLine2', name: 'Address Line 2', field: 'addressLine2', sortable: true },
            { id: 'suburb', name: 'Suburb', field: 'suburb', sortable: true },
            { id: 'city', name: 'City', field: 'city', sortable: true },
            { id: 'postalCode', name: 'Postal Code', field: 'postalCode', sortable: true }
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute('doctor', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute('doctor', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return doctorSelectedRowAction(args.rows[0]);
    }
}
