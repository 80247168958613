import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, BindingEngine, computedFrom, Disposable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules, Validator } from 'aurelia-validation';
import { Entity, IEntity } from 'plugins/data-models';
import { emptyGuid, isEmptyGuid, isGuid } from '@dts/scriptlib';
import { AuthService } from 'services/auth-service';
import { AddUserModel, RegisterModel, UpdateUserModel } from 'services/identity-repository';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { IdentityRepository } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';

@autoinject()
export class Index extends GenericEntity {

    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string };

    // abstract get displayName();
    @computedFrom('entity.userName')
    get displayName() {
        const entity = this.entity as AspNetUser;
        if (entity.userName) {
            return `${entity.userName}`;
        }
        return '';
    }

    get canUpdatePassword() {
        if (this.entity) {
            return (this.entity as AspNetUser).password?.length >= 6;
        }
        return false;
    }

    errors = [];

    canUpdateEmail = false;
    emailIsLocked = true;

    @observable private selectedRole = null;
    private roles: any[];
    private busyUpdatePassword = false;

    private readonly subscriptions: Disposable[] = [];

    constructor(repository: IdentityRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly impiloRepository: ImpiloRepository,
        private readonly router: Router, private readonly authService: AuthService) {
        super(repository, validator);

        this.entity = new AspNetUser();
        const entity = this.entity as AspNetUser;

        entity.id = emptyGuid();
        entity.email = this.authService.currentUser().email;
        entity.pharmacyGuid = this.authService.currentPharmacyGuid();
        entity.isActive = true;
        entity.password = '';
    }

    bind() {
        return this.controller.validate();
    }

    get canCreate() {
        this.cancelled = !this.isPharmacyAllowedTo.isAllowed;
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    async activate(params, routeConfig, navigationInstruction) {
        console.log('activate');
        this.roles = await this.impiloRepository.lookups().roles();

        if (isGuid(params.guid)) {
            this.isPharmacyAllowedTo = await (this.impiloRepository as ImpiloRepository).isPharmacyAllowedTo('editUser');

            if (!this.isPharmacyAllowedTo.isAllowed) {
                return;
            }

            const result = await (this.repository as IdentityRepository).getAspNetUser(params.guid);
            if (result.count > 0) {
                this.entity = AspNetUser.map(result.results[0]);
                // tslint:disable-next-line: no-string-literal
                this.entity['password'] = '';
            }

            this.selectedRole = (this.entity as AspNetUser).roleName;
        } else {
            this.isPharmacyAllowedTo = await (this.impiloRepository as ImpiloRepository).isPharmacyAllowedTo('createUser');
        }
    }

    // abstract deactivate();
    deactivate() {
        this.subscriptions.forEach(value => {
            value.dispose();
        });

        this.subscriptions.splice(0);
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('users');
    }

    async ok() {

        await this.apply();

        if (!this.isDirty) {
            this.escape();
        }
    }

    async apply() {
        if (this.canApply) {
            if (await this.canMerge()) {
                this.busyApply = true;
                const entity = (this.entity as AspNetUser);

                if (this.isNew) {
                    const addUserModel: AddUserModel = {
                        userName: entity.userName,
                        password: entity.password,
                        email: entity.email,
                        emailConfirmed: entity.emailConfirmed,
                        fullname: entity.fullname,
                        phoneNumber: entity.phoneNumber,
                        isActive: entity.isActive,
                        role: entity.roleName,
                        pharmacyGuid: entity.pharmacyGuid
                    };

                    const result = await (this.repository as IdentityRepository).addUser(addUserModel);
                    this.errors.splice(0);
                    if (!result.ok) {
                        result.errors.forEach((e) => {
                            this.errors.push(e.description);
                        });
                    } else {
                        this.entity = AspNetUser.map(result.results[0]);
                        // tslint:disable-next-line: no-string-literal
                        this.entity['password'] = '';
                        this.selectedRole = (this.entity as AspNetUser).roleName;
                        this.success(`${this.displayName} is saved.`);
                    }
                } else {
                    const updateUserModel: UpdateUserModel = {
                        id: entity.id,
                        fullname: entity.fullname,
                        phoneNumber: entity.phoneNumber,
                        emailConfirmed: entity.emailConfirmed,
                        isActive: entity.isActive,
                        role: entity.roleName,
                        pharmacyGuid: entity.pharmacyGuid,
                    };

                    const result = await (this.repository as IdentityRepository).updateUser(updateUserModel);

                    this.errors.splice(0);
                    if (!result.ok) {
                        result.errors.forEach((e) => {
                            this.errors.push(e.description);
                        });
                    } else {
                        this.entity = AspNetUser.map(result.results[0]);
                        // tslint:disable-next-line: no-string-literal
                        this.entity['password'] = '';
                        this.selectedRole = (this.entity as AspNetUser).roleName;
                        this.success(`${this.displayName} is saved.`);
                    }
                }

                this.busyApply = false;
            } else {
                this.warning('Please ensure all fields is valid.');
                const invalid = await this.entity.invalidFields(this.validator);
                console.log(invalid);
            }
        }
    }

    selectedRoleChanged(newValue, oldValue) {
        if (this.entity) {
            (this.entity as AspNetUser).roleName = newValue;
        }
    }

    async updatePassword() {
        this.busyUpdatePassword = true;
        const entity = (this.entity as AspNetUser);
        const result = await (this.repository as IdentityRepository).updatePassword(entity.userName, entity.password);
        this.errors.splice(0);
        if (!result.ok) {
            result.errors.forEach((e) => {
                this.errors.push(e.description);
            });
        } else {
            this.success(`New password for ${this.displayName} is saved.`);
        }
        entity.password = '';
        this.busyUpdatePassword = false;
    }

    enableUpdateEmail() {
        this.canUpdateEmail = true;
    }

    private readonly stringMatcher = (a, b) => {
        if (a && b) {
            return a === b;
        }

        return false;
    }
}

class AspNetUser extends Entity<AspNetUser> {

    id: string;
    email?: string;
    emailConfirmed: boolean = false;
    userName?: string;
    password?: string;
    phoneNumber?: string;
    fullname?: string;
    isActive: boolean;
    isRoot: boolean;
    pharmacyGuid?: string;
    pharmacyName: string;
    roleId: string;
    roleName: string;
    // role: any;

    constructor() {
        super('AspNetUser');

        ValidationRules
            .ensure((e: AspNetUser) => e.id).required().satisfiesRule('guid')
            .ensure(e => e.userName).required().maxLength(256)
            .ensure(e => e.password).minLength(6)
            .ensure(e => e.email).required().maxLength(256)
            .ensure(e => e.emailConfirmed).required().satisfiesRule('bit')
            .ensure(e => e.fullname).required().maxLength(255)
            .ensure(e => e.phoneNumber).maxLength(30)
            .ensure(e => e.isActive).required().satisfiesRule('bit')
            .ensure(e => e.pharmacyGuid).required().satisfiesRule('guid')
            .on(this);
    }

    static map(src: any): IEntity {
        const entity = Object.assign(new AspNetUser(), src) as AspNetUser;

        entity.original = { ...entity };
        return entity;
    }

    static create(src?: Partial<AspNetUser>): IEntity {
        return Object.assign(new AspNetUser(), src);
    }

    map(src: any): IEntity {
        return AspNetUser.map(src);
    }

    isDirty() {
        if (this.original) {
            const equal =
                this.original.id == this.id &&
                this.original.userName == this.userName &&
                this.original.email == this.email &&
                this.original.emailConfirmed == this.emailConfirmed &&
                this.original.fullname == this.fullname &&
                this.original.phoneNumber == this.phoneNumber &&
                this.original.isActive == this.isActive &&
                this.original.roleName == this.roleName;
            return !equal;
        }
        return false;
    }

    isNew() {
        return this.id == null || isEmptyGuid(this.id);
    }
}
