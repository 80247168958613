import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration, NavigationInstruction } from 'aurelia-router';
import { ToastrService } from 'services/toastr-service';
import { OffCanvas } from '@features/offcanvas'
import { AppService } from './app-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class App {

    private router: Router;
    private offCanvas: OffCanvas;

    constructor(private readonly toastr: ToastrService, private readonly appService: AppService, eventAggregator: EventAggregator) {

        eventAggregator.subscribe('router:navigation:processing', () => {
            this.offCanvas?.hide();
        });
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./routes/home/index'), title: 'Home', nav: true },
            { route: 'patients', name: 'patients', moduleId: PLATFORM.moduleName('./routes/patients/index'), title: 'Patients', nav: true },
            { route: 'groups', name: 'groups', moduleId: PLATFORM.moduleName('./routes/groups/index'), title: 'Patient Groups', nav: true },
            { route: 'print', name: 'print', moduleId: PLATFORM.moduleName('./routes/print/index'), title: 'Print', nav: true },
            { route: 'reports', name: 'reports', moduleId: PLATFORM.moduleName('./routes/reports/index'), title: 'Reports', nav: true },
            { route: 'documents', name: 'documents', moduleId: PLATFORM.moduleName('./routes/documents/index'), title: 'Documents', nav: true },
            { route: 'admin', name: 'admin', moduleId: PLATFORM.moduleName('./routes/admin/index'), title: 'Admin', nav: true },
            { route: 'account', name: 'account', moduleId: PLATFORM.moduleName('./routes/account/index'), title: 'Account', nav: true },
            { route: 'support', name: 'support', moduleId: PLATFORM.moduleName('./routes/support/index'), title: 'Support', nav: true },
            { route: 'pharmacies', name: 'choosePharmacy', moduleId: PLATFORM.moduleName('./routes/pharmacies/index'), title: 'Pharmacies', nav: true, settings: { auth: true } },
            { route: 'notices', name: 'notices', moduleId: PLATFORM.moduleName('./routes/notices/index'), title: 'Notices', nav: true },
        ]);
        this.router = router;
    }

    public attached() {
        console.log('Blister plugin loaded');

        const myOffcanvas = document.getElementById('offcanvasElement');
        this.offCanvas = new OffCanvas(myOffcanvas);
    }

    showCanvas(event: MouseEvent) {
        event.stopImmediatePropagation();
        queueMicrotask(() => this.offCanvas.show());
        return true;
    }
}
