import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo, Store } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { medicationFullTextAction, medicationSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'medications')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    @observable private readonly activeOnly: boolean = true;
    @observable private readonly missingImages: boolean = false;

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {
            if (this.filter.trim().length >= 0) {
                this.isWaiting = true;
                const response = await (this.repository.filterMedication(this.filter, this.activeOnly, this.missingImages, page, size, 0));
                this.isWaiting = false;

                await medicationSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((medication: ImpiloDb.Tables.Medication) => {
                    return medication;
                });

                return response;
            }
        }, () => medicationFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    activeOnlyChanged(newValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    missingImagesChanged(newValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: async (e: Event, args: OnEventArgs) => {
                    const medication = args.dataContext as ImpiloDb.Tables.Medication;
                    this.router.navigateToRoute('medication', { guid: medication.guid });
                }
            },
            { id: 'code', name: 'Code', field: 'code', sortable: true },
            { id: 'name', name: 'Name', field: 'name', sortable: true },
            { id: 'description', name: 'Description', field: 'description', sortable: true },
            { id: 'schedule', name: 'Schedule', field: 'schedule', sortable: true },
            { id: 'strength', name: 'Strength', field: 'strength', sortable: true },
            { id: 'form', name: 'Form', field: 'form', sortable: true },
            { id: 'isActive', name: 'Active', field: 'isActive', sortable: true, formatter: Formatters.checkmark },
            { id: 'isDiscontinued', name: 'Discontinued', field: 'isDiscontinued', sortable: true, formatter: Formatters.checkmark },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute('medication', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute('medication', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return medicationSelectedRowAction(args.rows[0]);
    }
}
