import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { fileToBase64, image2base64, resizeImage, urlToFile } from '@resources/utils/image-utils';
import { autoinject, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    private readonly files: File[] = [];
    private readonly subscriptions: Disposable[] = [];

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator,
        private readonly router: Router, private readonly bindingEngine: BindingEngine) {
        super(repository, validator);
    }

    bind() {
        return this.controller.validate();
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        const pharmacyGuid = localStorage.getItem('pharmacy');

        if (isGuid(pharmacyGuid)) {
            const result = await this.repository.entity('Pharmacy', pharmacyGuid, ImpiloDb.Tables.Pharmacy.map);
            this.entity = (result.results[0]);

            const entity = this.entity as ImpiloDb.Tables.Pharmacy;

            if (entity.logo) {
                const base64 = image2base64(entity.logo, 'image/png');
                const file = await urlToFile(base64, entity.logoName);
                this.files.push(file);
            }
        }

        this.subscriptions.push(this.bindingEngine.collectionObserver(this.files)
            .subscribe(async (splices) => {
                if (splices.length > 0) {
                    if (this.files.length > 0) {
                        const resized = await resizeImage({ maxSize: 512, file: this.files[0] });
                        const img = await fileToBase64(resized);
                        const arr = (img as any).split(',');
                        (this.entity as ImpiloDb.Tables.Pharmacy).logo = arr[1];
                        (this.entity as ImpiloDb.Tables.Pharmacy).logoName = this.files[0].name;

                    } else {
                        (this.entity as ImpiloDb.Tables.Pharmacy).logo = null;
                        (this.entity as ImpiloDb.Tables.Pharmacy).logoName = null;
                    }
                    return this.files.length;
                }
            }));
    }

    // abstract deactivate();
    deactivate() {
        this.subscriptions.forEach(value => {
            value.dispose();
        });

        this.subscriptions.splice(0);
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('pharmacies');
    }

    // abstract get displayName();
    @computedFrom('entity.name')
    get displayName() {
        const entity = this.entity as ImpiloDb.Tables.Pharmacy;
        if (entity?.name) {
            return `${entity.name}`;
        }

        return '';
    }
}
