import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { fileToBase64, image2base64, resizeImage, urlToFile } from '@resources/utils/image-utils';
import { autoinject, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    private readonly files: File[] = [];
    private subscription: Disposable;

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router, private readonly bindingEngine: BindingEngine) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Medication();
        const entity = this.entity as ImpiloDb.Tables.Medication;
        entity.isActive = true;
        entity.isDiscontinued = false;
        entity.created = new Date();
    }

    bind() {
        return this.controller.validate();
    }

    beforeMerge() {
        const entity = this.entity as ImpiloDb.Tables.Medication;
        entity.lastEdited = new Date();
        return super.beforeMerge();
    }
    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.entity = await this.entity.get(this.repository, params.guid) as ImpiloDb.Tables.Medication;
            const medication = this.entity as ImpiloDb.Tables.Medication;
            // console.log(medication);

            if (medication.photo) {
                const base64 = image2base64(medication.photo, 'image/png');
                const file = await urlToFile(base64, medication.photoName);
                this.files.push(file);
            }
        }

        this.subscription = this.bindingEngine.collectionObserver(this.files)
            .subscribe(this.filesChanged.bind(this));
    }

    // abstract deactivate();
    deactivate() {
        this.subscription.dispose();
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('medications');
    }

    // abstract get displayName();
    @computedFrom('entity.name')
    get displayName() {
        const medication = this.entity as ImpiloDb.Tables.Medication;
        return `${medication.name}`;
    }

    async filesChanged() {
        console.log(this.files);

        if (this.files.length > 0) {
            const resized = await resizeImage({ maxSize: 512, file: this.files[0] });
            const img = await fileToBase64(resized);
            // const img = await fileToBase64(this.files[0]);

            const arr = (img as any).split(',');
            (this.entity as ImpiloDb.Tables.Medication).photo = arr[1];
            (this.entity as ImpiloDb.Tables.Medication).photoName = this.files[0].name;

        } else {
            (this.entity as ImpiloDb.Tables.Medication).photo = null;
        }

        return this.files.length;
    }
}
