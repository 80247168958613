import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Doctor();
    }

    bind() {
        return this.controller.validate();
    }

    beforeMerge() {
        const entity = (this.entity as ImpiloDb.Tables.Doctor);

        entity.pharmacyGuid = (this.repository as ImpiloRepository).pharmacyGuid();
        entity.created = new Date();
        entity.lastEdited = new Date();
        return super.beforeMerge();
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.entity = await this.entity.get(this.repository, params.guid) as ImpiloDb.Tables.Doctor;
        }
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('doctors');
    }

    // abstract get displayName();
    @computedFrom('entity.name')
    get displayName() {
        const doctor = this.entity as ImpiloDb.Tables.Doctor;
        if (doctor.name) {
            return `${doctor.name}`;
        }

        return '';
    }
}
