import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Index {
    private router: Router;

    constructor(private readonly authService: AuthService) {
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./home/index'), title: 'Home' },
            { route: 'pharmacy', name: 'pharmacy', moduleId: PLATFORM.moduleName('./pharmacy/index'), title: 'Pharmacy', nav: true },
            { route: 'users', name: 'users', moduleId: PLATFORM.moduleName('./users/index'), title: 'Users', nav: true },
            { route: 'doctors', name: 'doctors', moduleId: PLATFORM.moduleName('./doctors/index'), title: 'Doctors', nav: true },
            { route: 'medications', name: 'medications', moduleId: PLATFORM.moduleName('./medications/index'), title: 'Medication', nav: true },
        ]);
        this.router = router;
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }
}
