import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo, Store } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { IdentityRepository } from 'services/repositories';
import { userFullTextAction, userSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'users')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    constructor(private readonly repository: IdentityRepository, private readonly router: Router) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {

            // const filter = this.filter.trim().length == 0 ? authService.currentUser().email : this.filter;
            const filter = this.filter;

            if (filter.trim().length >= 0) {
                this.isWaiting = true;
                const response = await this.repository.filterAspNetUser(filter, page, size);
                this.isWaiting = false;

                await userSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity) => {
                    return entity;
                });

                return response;
            }
        }, () => userFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    attachedDone() {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext;
                    this.router.navigateToRoute('user', { guid: entity.id });
                }
            },
            { id: 'userName', name: 'User Name', field: 'userName', sortable: true },
            { id: 'email', name: 'Email', field: 'email', sortable: true },
            { id: 'Fullname', name: 'Fullname', field: 'fullname', sortable: true },
            { id: 'phoneNumber', name: 'Phone', field: 'phoneNumber', sortable: true },
            { id: 'pharmacyName', name: 'Pharmacy', field: 'pharmacyName', sortable: true },
            { id: 'roleName', name: 'Role ', field: 'roleName', sortable: true },
            { id: 'isActive', name: 'Active', field: 'isActive', sortable: true, formatter: Formatters.checkmark },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity) {
        this.router.navigateToRoute('user', { guid: entity.id });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute('user', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return userSelectedRowAction(args.rows[0]);
    }
}
