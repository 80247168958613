import { autoinject, singleton, Container } from 'aurelia-framework';
import { Router, NavigationInstruction } from 'aurelia-router';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { get, set } from 'idb-keyval';
import { DialogService } from 'aurelia-dialog';
import { NoticeDialog } from '@resources/dialogs/notice-dialog';
import { AuthService } from 'services/auth-service';


@singleton()
@autoinject()
export class AppService {

    routes = [];

    notices: ImpiloDb.Tables.Notice[] = [];

    noticeInterval = 60;

    constructor(private readonly router: Router, private readonly repository: ImpiloRepository,private readonly dialogService: DialogService) {
        this.loadNotices();
    }

    async loadNotices() {
        console.log(`loadNotices interval ${this.noticeInterval} seconds`);
        setInterval(async () => {

            const authService = Container.instance.get(AuthService);
            const isLoggedIn = authService.isAuthenticated();
            if (isLoggedIn === undefined || !isLoggedIn) {
                return;
            }

            // get the current notices
            const current = ((await get('notices')) as ImpiloDb.Tables.Notice[]) ?? [];

            // refresh the notices from the server
            const response = await this.repository.getNotices();
            this.notices = response.results.map((entity: ImpiloDb.Tables.Notice) => {
                return entity;
            }).sort((a, b) => a.created < b.created ? 1 : -1);
            await set('notices', this.notices);

            // compare current with new, and add all the new ones in a new array
            const currentNotices = current.map(v => v.guid);

            const newNotices = this.notices.map(v => v.guid);
            const added = newNotices.filter(v => !currentNotices.includes(v));
            this.notices.push(...added.map(v => this.notices.find(w => w.guid === v)));

            // remove all the old ones
            const removed = currentNotices.filter(v => !newNotices.includes(v));
            removed.forEach(v => this.notices.splice(this.notices.findIndex(w => w.guid === v), 1));

            console.log(added, removed);

            if (added.length > 0) {
                const latestNotice = this.notices[0];
                await this.dialogService.open({ viewModel: NoticeDialog, model: latestNotice , lock: false });
            }

        }, this.noticeInterval * 1000);
    }

    clear() {
        this.routes.splice(0);
    }

    unshift(guid: string, title: string, currentInstruction: NavigationInstruction) {

        const index = this.routes.findIndex((v) => v.guid === guid);
        if (index > -1) {
            this.routes.splice(index, 1);
        }

        const url = `#${currentInstruction.router.baseUrl}`;

        this.routes.unshift({
            guid,
            title,
            href: url
        });
    }
}