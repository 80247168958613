import { YoutubeDialog } from '@resources/dialogs/youtube-dialog';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Index {

    youtubeLinks = [
        { header: 'Setting up your Blister Center', link: 'https://www.youtube.com/embed/Ee6jSz0-IzU' },
        { header: 'Using the Pressure (Cold) Seal System', link: 'https://www.youtube.com/embed/kR1Qy1haKwQ' },
        { header: 'Using the Heat Seal System', link: 'https://www.youtube.com/embed/RRNJeBJwA00' },
        { header: 'Using Plus-pak patches to repair blister pack', link: 'https://www.youtube.com/embed/8GuBX0qkCmY' },
        { header: 'Using Rx labels to change time slot columns on blister packs', link: 'https://www.youtube.com/embed/NJI77ooeIEU' },
        { header: 'Setting up your Heat Sealer', link: 'https://www.youtube.com/embed/IBPYfEH4fJ8' },
        { header: 'Care Home Training Video', link: 'https://www.youtube.com/embed/Tcx56G45VJY' },
        { header: 'Medical Trolley for Blister Packs in Care Home', link: 'https://www.youtube.com/embed/clk8jK_UFrk' },
        { header: 'DB500', link: 'https://www.youtube.com/embed/xyRRfg319CQ' },
        { header: 'Marketing Video', link: 'https://www.youtube.com/embed/EGe_7RtmizQ' }
    ];

    constructor(private readonly dialogService: DialogService, private readonly authService: AuthService) {
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }

    async showModal(index) {
        await this.dialogService.open({ viewModel: YoutubeDialog, model: this.youtubeLinks[index], lock: false });
    }
}
