import { DialogController } from 'aurelia-dialog';
import { autoinject, observable } from 'aurelia-framework';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { PrintService } from 'services/print-service';
import { ValidationRules } from 'aurelia-validation';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';

@autoinject()
export class ChooseAccountDialog {
    model: {
        title: string;
        accountType: string;
    };

    title = "Are you sure you want to register for this account type?";
    accountType = 'Advanced'

    @observable selectedChoice = 0;

    constructor(private readonly repository: ImpiloRepository, private readonly dialogController: DialogController, private readonly controller: BootstrapValidationController) {

        ValidationRules
            .ensure('emailAddress').required().email()
            .on(this);
    }

    async ok() {

        const validate = await this.controller.validate();

        let accountTypeId = 0;
        if (validate.valid) {

            switch (this.accountType) {
                case 'Advanced':
                    accountTypeId = 1;
                    break;
                case 'Premium':
                    accountTypeId = 2;
                    break;
            }

            const billedAnnually = this.selectedChoice === 1 ? 1 : 0;
            await this.repository.updateAccountType(accountTypeId , billedAnnually );
            this.dialogController.ok();
        } else {
            queueMicrotask(() => {
            });
        }
    }



    cancel() {
        this.dialogController.cancel();
    }

    async activate(model) {
        this.model = model;
        this.title = model.title;
        this.accountType = model.accountType;
        const validate = await this.controller.validate();
    }

    deactivate() {
        //
    }
}
